body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.slider--text {
    position: absolute;
    bottom: 4em;
    left: 2em;
    width: 50%;
    height: fit-content;
    padding: 1em;
    border-radius: 4px;
    z-index: 3;
    display: flex;
    flex-direction: column;
}
::-webkit-scrollbar {
    width: 10px;
    visibility: hidden;
}
::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(255, 255, 255, 0.1);
}
::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.2);
}

.bg-red {
    background-color: #ff0000;
    color: white;
}

.opacityhide {
  opacity: 0;
}

.main-wrapper >  div:nth-child(1) {
  margin-top: 6vw;
}

.mantine-search-modal .mantine-Modal-inner {
    padding-top: 120px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.mantine-search-modal .mantine-Paper-root {
    padding: 0;
    height: 50px;
    width: 100%;
    max-width: 600px;
    margin-left: -10px;
}
.mantine-search-modal .mantine-Input-wrapper {
    height: 50px;
}
.mantine-search-modal .mantine-Input-icon {
    width: 50px;
}
.mantine-search-modal .mantine-Input-input {
    height: 50px;
    border-color: transparent;
    padding-left: 50px;
    padding-right: 16.666px;
    font-size: 18px;
}
.mantine-search-modal .mantine-Modal-body {
    background-color: #25262b;
}
.mantine-search-modal .mantine-Input-rightSection {
    width: 50px;
}
.profile-image .mantine-Image-image {
    border: 3px solid #FF0000;
}
.main-wrapper .mantine-Carousel-slide,.anime-card-wrapper {
    transition-timing-function: cubic-bezier(0,0,.2,1);
    transition-duration: .3s;
}
.main-wrapper .mantine-Carousel-slide:hover, .anime-card-wrapper:hover{
    transform: translateY(-0.25rem);
}